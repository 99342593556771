import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isLoadingList } from "../../_reducers/playlist.reducer";
import { DefaultButton, DefaultColumnContainer, StatusBadge } from "../../_styles";
import albumImagePlaceholder from "_assets/images/talea-album-placeholder.png";
import "react-multi-carousel/lib/styles.css";
import { Check, Comment, ThumbUp, Warning } from "@material-ui/icons";
import { Button, Col, Container, Image, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { sampleActions } from "../../_actions/sample.actions";
import TaleaLogoImage from "../../_assets/images/talea_logo_white.png";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import media from "../../_helpers/media";
import PlayOverlayImage from "../../_assets/images/PlayOverlay.png";
import { SamplesModal } from "./SamplesStyledWidgets";
import { isMobileDevice } from "react-select/lib/utils";
import { playlistActions } from "../../_actions";


const BrandContainerCol = styled(Col)`
    align-items: stretch;
    justify-content: center;
    padding: 30px 0;
`;

const SampleInfo = styled(DefaultColumnContainer)`
    ${media.mobile`    
  padding: 15px 0px 15px 0px; 
`};
    ${media.tablet`
padding: 0px 0px 15px 30px;
`}
    color: white;
`;

const PlaylistsContainer = styled(Container)`
    ${media.mobile`    
    width: 100% 
`};
    ${media.tablet`
    width: 70%
`}
`;
const ClientName = styled.span`
    font-size: 14px;
    text-transform: uppercase;

    color: #ECA521;
`;
const SampleName = styled.span`
    ${media.mobile`    
  font-size: 24px; 
`};
    ${media.tablet`
font-size: 58px;
`}

`;

const SubmitFeedbackButton = styled(Button)`
    background-color: #525252;
    border-color: transparent;
    color: white;
    border-radius: 25px;
    padding: 12px;
    width: 214px;
    font-size: 15px;
    font-weight: 500;

    :hover, :focus {
        color: white;
        background-color: #ECA521;
        outline: none !important;
        box-shadow: none;
        border-color: white;
    }

`

const SampleDescription = styled.span`
    align-items: flex-start;
    color: #ABA7A7;
    line-height: 1.25rem;
`;

const PlaylistImageContainer = styled.div`
    position: relative;
    ${media.mobile`    
  width: 100px;
  height: 100px;
`};
    ${media.tablet`
width: 100px;
height: 100px;
`}

    object-fit: fit;
    color: white;
`;

const PlayOverlay = styled(Image)`
    :hover {
        cursor: pointer;
    }

    ${media.mobile`    
  width: 30px;
  height: 30px;
`};

    ${media.tablet`    
  width: 30px;
  height: 30px;
`};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const PlaylistImage = styled.img`
    :hover {
        cursor: pointer;
    }

    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
    color: white;
`;
const PlaylistName = styled.div`
    ${media.mobile`    
  font-size: 16px;  
`};
    ${media.tablet`
font-size: 21px;
`}
    color: white;
    max-height: 50px;
    line-height: 1rem;
    text-align: left;
`;


const PlaylistDescription = styled.div`
    font-size: 16px;
    margin-top: 5px;
    line-height: 20px;
    color: #AEAEAE;
    text-align: left;
`;

const Brand = styled.img`
    width: 150px;
`;

export const VotesBadge = styled(StatusBadge)`
    background-color: ${props => props.novotes ? "#636363" : "#3579F6"};
`;

const SampleImage = styled.img`
    ${media.mobile`    
  width: 100%;  
`};
    ${media.tablet`
width: 214px;
`}

    border: 0;
`;

const BorderRow = styled(Row)`
    border-top: 1px solid #3C3C47 !important;
    position: absolute;
    width: 100%;
    left: 0;
`;

const PlaylistTitleRow = styled(Row)`
    ${media.mobile`              
          margin-top: 55px;
          margin-left:5px;   
      `};
    ${media.tablet`          
          margin-top: 100px;
          margin-left:30px;
    `};
    margin-bottom: 30px;
    font-size: 32px;
    color: white;
`;

const SubmitFeedbackButtonRow = styled(Row)`
    ${media.mobile`    
          margin-top: 20px;
`};
    ${media.tablet`
           margin-top: 20px;
          padding-left: 30px;
`};
`

class Sample extends React.Component {
  state = {
    id: "",
    currentlyPlaying: "",
    name: "",
    showFeedbackModal: false,
    showInstructionsModal: (localStorage.getItem("shownSampleInfoModal") === null),
  };

  componentDidMount() {
    const action = sampleActions.getSample(this.props.sampleId);
    this.props.dispatch(action);
    this.props.dispatch(playlistActions.getSimple());
  }

  componentDidUpdate() {
  }

  submitFeedbackFormClicked(name, feedback) {
    sampleActions.postSampleFeedback(this.props.dispatch, this.props.sample.token, feedback, name);
  }

  InstructionsModal = () => {
    const hideInstructionsModal = () => {
      this.setState({showInstructionsModal: false});
      localStorage.setItem("shownSampleInfoModal", "1");
    }

    return <SamplesModal centered show={true} backdrop={true} size={'small'}
        onHide={hideInstructionsModal}>
            <Modal.Header style={{
              backgroundColor: "#353341",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 0,
              borderRadius: "15px 15px 0 0",
              color: "#F3ECEC"
            }} closeButton>
                <Modal.Title style={{
                  width: "100%", paddingLeft: 37, textAlign: "center"
                }}>Instructions on using Talea Samples</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#353341", color: "#F3ECEC", borderRadius: "0 0 15px 15px",}}>
                <>
                    <Row xs={12} className="align-items-center d-flex justify-content-center"
                        style={{marginBottom: 20}}>
                        <Col xs={"auto"} className={"d-none d-sm-block"}>
                            <Avatar style={{
                              background: "#424052",
                              padding: 30,
                              height: 125,
                              width: 125,
                              color: "white",
                              borderRadius: "50%"
                            }}>
                                <Image style={{width: 40, height: 40}} src={PlayOverlayImage}></Image>
                            </Avatar>
                        </Col>
                        <Col xs={"auto"} className={"d-none d-sm-block"}>
                            ---------
                        </Col>
                        <Col xs={"auto"}>
                            <Avatar style={{
                              background: "#424052",
                              padding: 30,
                              height: 125,
                              width: 125,
                              color: "white",
                              borderRadius: "50%"
                            }}>
                                <ThumbUp fontSize={"large"}></ThumbUp>
                            </Avatar>
                        </Col>

                    </Row>
                    <Row style={{marginBottom: 40}}>
                        <Col style={{lineHeight: "39.78px", fontSize: 20, fontWeight: 400, padding: 30}}>
                            <ol>
                                <li>
                                    Listen through all playlists.
                                </li>
                                <li>
                                    Vote and comment on the at least 1 track per playlist.
                                </li>
                                <li>
                                    After listening and voting - <span
                                    style={{color: "#EBAD18"}}>Submit your feedback</span> for our curators to review.
                                </li>
                            </ol>
                            <p>If you have any questions, please contact our support at <span
                                style={{color: "#EBAD18"}}>contact@talea-agency.com</span> for assistance.</p>
                        </Col>
                    </Row>
                    <Row>
                        <DefaultButton
                            style={{
                              width: 150,
                              height: 35,
                              border: 0,
                              color: "black",
                              backgroundColor: "#EFEFEF",
                              fontWeight: 500,
                              margin: "auto",
                              marginBottom: 15
                            }}
                            onClick={hideInstructionsModal}>
                            Ok
                        </DefaultButton>
                    </Row>
                </>
            </Modal.Body>
        </SamplesModal>
  }

  FeedbackModal = () => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [feedback, setFeedback] = useState('');
    return <SamplesModal centered show={true} backdrop={true} size={'small'}
        onHide={() => {
          this.setState({showFeedbackModal: false});
        }}>
            <Modal.Header style={{
              backgroundColor: "#353341",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 0,
              borderRadius: "15px 15px 0 0",
              color: "#F3ECEC"
            }} closeButton>
                <Modal.Title style={{
                  width: "100%", paddingLeft: 37, textAlign: "center"
                }}>{step === 1 ? "Submit Feedback" : "Thanks for listening"}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{backgroundColor: "#353341", color: "#F3ECEC", borderRadius: "0 0 15px 15px",}}>
                {step === 0 ? <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 40}}>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                <Avatar style={{
                                  background: "#EBAD18",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <ThumbUp fontSize={"large"}></ThumbUp>
                                </Avatar>
                            </Col>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                ---------
                            </Col>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#424052",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "#EBAD18",
                                  borderRadius: "50%"
                                }}>
                                    <Warning></Warning>
                                </Avatar>
                            </Col>

                        </Row>
                        <Row style={{marginBottom: 40}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Hold up, You still haven’t listened to all the playlists in the sample yet - are you
                                sure want to submit feedback now?
                            </Col>
                        </Row>
                        <Row>
                            <DefaultButton
                                style={{
                                  width: 150,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: "#EFEFEF",
                                  fontWeight: 600,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setStep(1);
                                }}>
                                Next
                            </DefaultButton>
                        </Row>
                    </>

                    : step === 1 ? <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 40}}>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                <Avatar style={{
                                  background: "#EBAD18",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <ThumbUp fontSize={"large"}></ThumbUp>
                                </Avatar>
                            </Col>
                            <Col xs={"auto"} className={"d-none d-sm-block"}>
                                ---------
                            </Col>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#424052",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "white",
                                  borderRadius: "50%"
                                }}>
                                    <Comment></Comment>
                                </Avatar>
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", fontfamily: "Apercu"}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Thanks for listening! Do you have any final thoughts or comments before submitting
                                your
                                feedback?
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginTop: 20}}>
                            <Col>
                                    <textarea value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name*" style={{
                                      resize: "none",
                                      overflow: "hidden",
                                      height: 40,
                                      width: "100%",
                                      borderRadius: "15px",
                                      margin: "auto",
                                      padding: 20,
                                      paddingTop: 12,
                                      backgroundColor: "#424052",
                                      color: "#F3ECEC"
                                    }} />
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginTop: 20}}>
                            <Col>
                    <textarea onChange={(event) => setFeedback(event.target.value)}
                        value={feedback}
                        placeholder={"Leave a comment..."}
                        style={{
                          resize: "none",
                          height: 150,
                          borderRadius: "15px",
                          width: "100%",
                          margin: "auto",
                          padding: 20,
                          backgroundColor: "#424052",
                          color: "#F3ECEC"
                        }}></textarea>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 25}}>
                            <DefaultButton
                                style={{
                                  width: 225,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: "#EBAD18",
                                  fontfamily: "Apercu",
                                  fontWeight: 500,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setStep(2);
                                  this.submitFeedbackFormClicked(name, feedback);
                                }}>
                                Submit Feedback
                            </DefaultButton>
                        </Row>
                    </> : <>
                        <Row xs={12} className="align-items-center d-flex justify-content-center"
                            style={{marginBottom: 20}}>
                            <Col xs={"auto"}>
                                <Avatar style={{
                                  background: "#00B200",
                                  padding: 30,
                                  height: 125,
                                  width: 125,
                                  color: "black",
                                  borderRadius: "50%"
                                }}>
                                    <Check fontSize={"large"}></Check>
                                </Avatar>
                            </Col>
                        </Row>
                        <Row style={{margin: "auto", marginBottom: 20}}>
                            <Col style={{lineHeight: "1.3rem"}}>
                                Thank you {name}!<br /><br />
                                Your feedback has been submitted! We’ll be in touch shortly.<br /><br />
                                You can keep on listening to the sample as much as you like.<br /><br />
                                Talea Team.

                            </Col>
                        </Row>
                        <Row>
                            <DefaultButton
                                style={{
                                  width: 150,
                                  height: 35,
                                  border: 0,
                                  color: "black",
                                  backgroundColor: "#EFEFEF",
                                  fontWeight: 600,
                                  margin: "auto",
                                  marginBottom: 15
                                }}
                                onClick={() => {
                                  setFeedback("");
                                  setName("");
                                  this.setState({showFeedbackModal: false});
                                }}>
                                Ok
                            </DefaultButton>
                        </Row>
                    </>}
            </Modal.Body>
        </SamplesModal>
  }

  submitFeedbackClicked = (name, feedback) => {
    this.setState({showFeedbackModal: true, name: name, feedback: feedback});
  }

  playlistRow(playlist) {
    const PlaylistRow = styled(Row)`
        ${media.mobile`              
          margin-left: 5px;
          margin-bottom: 15px;   
`};
        ${media.tablet`          
          margin-left: 30px;
          margin-bottom: 15px;
`};

        :hover {
            cursor: pointer;
        }
    `
    const BorderCol = styled(Col)`
        margin-left: 25px;
        border-top: 1px solid #3C3C47 !important;
    `;
    const voteCount = this.props.sample.votes.filter(v => v.playlist && v.playlist.id === playlist.id).length;
    const playlistId = playlist.id + (playlist.sort ? "_" + playlist.sort.id : "");
    const playlistName = playlist.name + (playlist.sort ? "_" + playlist.sort.name : "");
    return <><PlaylistRow className="align-items-center d-flex justify-content-center"
        onClick={() => this.props.history.push(`/clientsample/${this.props.sample.token}/playlist/${playlistId}`)}>
            <Col xs={"auto"} style={{padding: 0}}>
                <PlaylistImageContainer>
                    {playlist.artworkUrl && <PlayOverlay src={PlayOverlayImage}></PlayOverlay>}
                  <PlaylistImage
                      src={playlist.artworkUrl || albumImagePlaceholder}></PlaylistImage>
                </PlaylistImageContainer>
            </Col>
            <Col xs={8} style={{paddingLeft: 25}}>
                <PlaylistName title={playlistName}>{playlist.name}</PlaylistName>
                <PlaylistDescription>{playlist.description}</PlaylistDescription>
            </Col>
            <Col xs={1}>
                <VotesBadge novotes={voteCount === 0}>{voteCount} votes</VotesBadge>
            </Col>
        </PlaylistRow>
            <PlaylistRow style={{marginBottom: 20}} className="align-items-center d-flex justify-content-center">
                <Col xs={2}></Col>
                <BorderCol className={"border-top"}></BorderCol>
            </PlaylistRow>
        </>;
  }

  render() {
    const {
      sample,
      playlists
    } = this.props;
    const samplePlaylists = sample.playlists ? sample.playlists : [];
    const samplePlaylistSortsDtos = sample.playlistSortsDtos ? sample.playlistSortsDtos : [];
    const samplePlaylistElements = [...samplePlaylists];
    if (playlists.length !== 0) {
      samplePlaylistSortsDtos.forEach(playlistSort => {
        let playlist = JSON.parse(JSON.stringify(playlists.find(p => p.id === playlistSort.playlistId)));
        if (playlist) {
          playlist.sort = playlistSort;
          samplePlaylistElements.push(playlist);
        }
      });
    }


    const client = sample.client || {};
    samplePlaylistElements.sort((p1, p2) => p1.name.toLowerCase().localeCompare(p2.name.toLowerCase()));

    const renderSubmitFeedbackTooltip = (props) => (
        <Tooltip style={{backgroundColor: "white", color: "black"}} id="button-tooltip" {...props}>
                Submit your feedback when you finish listening to all samples
            </Tooltip>
    );

    const entering = (e) => {
      e.children[1].style.backgroundColor = 'white';
      e.children[1].style.color = 'black';
    };
    return (<>
            <Container style={{marginTop: 0}}>
                <Row className={"d-flex justify-content-center"}>
                    <BrandContainerCol xs={"auto"}>
                        <Brand src={TaleaLogoImage} />
                    </BrandContainerCol>
                </Row>
                <Row className={"d-flex justify-content-center"}>
                    <Col xs={"auto"}>
                        <SampleImage src={sample.artworkUrl || albumImagePlaceholder} alt="image" />
                    </Col>
                    <Col xs={12} sm={8}>
                        <SampleInfo>
                            <ClientName>{client.name}</ClientName>
                            <br />
                            <SampleName>{sample.name}</SampleName>
                            <br />
                            <SampleDescription>{sample.description} </SampleDescription>
                        </SampleInfo>

                        <SubmitFeedbackButtonRow>
                            <Col>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderSubmitFeedbackTooltip}
                                    onEntering={entering}
                                >
                                    <SubmitFeedbackButton onClick={this.submitFeedbackClicked}>
                                        <ThumbUp style={{marginRight: 10}}></ThumbUp>
                                        Submit feedback
                                    </SubmitFeedbackButton>
                                </OverlayTrigger>
                            </Col>
                        </SubmitFeedbackButtonRow>
                    </Col>
                </Row>
                <BorderRow style={{marginTop: 30}}></BorderRow>

                <Row>
                    <PlaylistsContainer>
                        <PlaylistTitleRow><Col xs={11} style={{paddingLeft: 35}}>Playlists</Col>{isMobileDevice() ? null :
                            <Col xs={1} style={{paddingLeft: 0, fontSize: 24, color: 'white'}}>Votes</Col>}</PlaylistTitleRow>
                      {samplePlaylistElements.map((playlist) => this.playlistRow(playlist, sample))}
                    </PlaylistsContainer>
                </Row>
              {this.state.showFeedbackModal && <this.FeedbackModal></this.FeedbackModal>}
              {this.state.showInstructionsModal && <this.InstructionsModal></this.InstructionsModal>}
            </Container>
        </>);
  }
}

const mapStateToProps = (state, props) => ({
  sampleId: props.match.params.sampleToken,
  loading: isLoadingList(state),
  sample: state.samples.sample,
  playlists: state.playlists.lists.list,
  alert: state.alert,
});

const connectedSample = withRouter(connect(mapStateToProps)(Sample));
export { connectedSample as Sample };

