import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { songsActions } from "_actions";
import { SearchFilter, SortTable } from '_components/Standard';
import { DefaultColumnContainer, DefaultLabel, DefaultRowContainer, PlayIcon, theme } from "_styles";

const Container = styled(DefaultColumnContainer)`
    height: 100%;
    width: 100%;
    align-items: flex-start;
    margin: 0px 54px;
`;

const NothingLabel = styled(DefaultLabel)`
    color: white;
    margin: 20px;
`;

const DataContainer = styled(DefaultColumnContainer)`
    width: 100%;
    padding: 20px;
`;

const Row = styled(DefaultColumnContainer)`
    margin-top: 20px;
`;

const DemoSearchBox = styled.div`
    margin-top: 15px;
`;

class SongSearch extends React.Component {
  state = {
    search: ""
  };

  componentDidMount() {
    this.handleSearch(this.props.query);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.handleSearch(this.props.query);
    }
  }

  handleSearch = search => {
    this.setState({search})
    this.props.dispatch(songsActions.songSearch(search))
  };

  render() {
    const {song = []} = this.props;

    return (
        <ThemeProvider theme={theme}>
      <Container>
        <DemoSearchBox><SearchFilter placeholder='Search' onChange={this.handleSearch}
            value={this.state.search} /></DemoSearchBox>
        {song.length === 0 ? (
            <NothingLabel></NothingLabel>
        ) : (
            <DataContainer>
            <Row>
              <Heading
                  name={`Tracks (${
                      song.tracks !== undefined ? song.tracks.length : 0
                  })`}
                  isOpen={true}
              />

              <SortTable
                  rows={song.tracks === undefined ? [] : song.tracks}
                  columns={[
                    {
                      fixed: 45, rowRenderer: row => (
                          <div className="hover-button">
                      <PlayIcon onClick={() => (
                          songsActions.play(this.props.dispatch, row.id, row.title, row.artist.name, row.artworkUrl)
                      )} />
                    </div>
                      )
                    },
                    {percent: 1, field: 'title', title: 'title'},
                    {percent: 1, field: 'artist.name', title: 'artist'},
                    {percent: 1, field: 'bpm', title: 'BPM'}
                  ]}
              />
            </Row>
            <Row>
              <Heading
                  name={`Artists (${
                      song.artists !== undefined ? song.artists.length : 0
                  })`}
                  isOpen={true}
              />
              <SortTable
                  rows={song.artists === undefined ? [] : song.artists}
                  columns={[
                    {percent: 1, field: 'name', title: 'name'}
                  ]}
              />
            </Row>
            <Row>
              <Heading
                  name={`Albums (${
                      song.albums !== undefined ? song.albums.length : 0
                  })`}
                  isOpen={true}
              />
              <SortTable
                  rows={song.albums === undefined ? [] : song.albums}
                  columns={[
                    {percent: 1, field: 'name', title: 'name'}
                  ]}
              />
            </Row>
          </DataContainer>
        )}
      </Container>
      </ThemeProvider>
    );
  }
}

const Heading = ({name, isOpen}) => {
  const HeadingContainer = styled(DefaultRowContainer)`
      width: 100%;
      align-items: flex-start;
      padding: 10px;
      border-bottom: 1px solid ${props => props.theme.darkGray};
  `;
  const NameLabel = styled(DefaultLabel)`
      color: white;
      font-size: 22px;
      font-weight: bold;
  `;

  return (
      <HeadingContainer>
      <NameLabel>{name}</NameLabel>
    </HeadingContainer>
  );
};

const mapStateToProps = state => ({
  alert: state.alert,
  song: state.songs.songSearch
});

const connectedSongSearch = withRouter(connect(mapStateToProps)(SongSearch));
export { connectedSongSearch as SongSearch };

