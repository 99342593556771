import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { alertActions, campaignActions, playlistActions } from "_actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClientDropdownFilter,
  CreateCampaign,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle,
  SortTable
} from "_components";
import { Matches } from '_helpers';
import { StandardDateMomentWithFormat, StatusBadge } from "_styles";
import { Button } from "react-bootstrap";
import { BaseUrl, getBearer } from "../_services/api";

const ACCOUNT_STATUS_ACTIVE = 'ACTIVE';
const ACCOUNT_STATUS_TRIAL = 'TRIAL';
// const ACCOUNT_STATUS_SUSPENDED = 'SUSPENDED';

export const Badge = styled(StatusBadge)`
    background-color: ${props => props.status === ACCOUNT_STATUS_TRIAL ?
            "purple" :
            props.status === ACCOUNT_STATUS_ACTIVE ? props.theme.onlineGreen :
                    props.status === "VOTES_PRESENT" ? '#72f75d' :
                            props.status === "NO_VOTES" ? '#cdcaca' :
                                    props.status === "FEED_BACK_SUBMITED" ? '#191993' :
                                            props.theme.offlineRed}
`;

const LinkButton = styled(Button)`
    display: flex;
    width: 75px;
    height: 25px;
    border-color: transparent;
    border-radius: 5px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${props => props.status === ACCOUNT_STATUS_TRIAL ?
            "purple" :
            props.status === ACCOUNT_STATUS_ACTIVE ? props.theme.onlineGreen :
                    props.status === "VOTES_PRESENT" ? '#3579F6' :
                            props.status === "NO_VOTES" ? '#636363' :
                                    props.status === "FEEDBACK_SUBMITED" ? '#429488' :
                                            props.theme.offlineRed}
`

class AllCampaigns extends React.Component {
  state = {
    showAddCampaign: false,
    search: "",
    campaign: undefined,
    statusFilter: undefined,
    filterClientId: -1
  };

  componentDidMount() {
    campaignActions.getAllCampaigns(this.props.dispatch);
    this.props.dispatch(playlistActions.getSimple());
  }

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  handleFilterType = selected => {
    this.setState({statusFilter: selected ? selected.id : undefined});
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  };

  handleEdit = info => {
    this.setState({showAddCampaign: true, campaign: info});
  };

  closeCampaign = () => {
    this.setState({showAddCampaign: false, campaign: undefined});
    campaignActions.getAllCampaigns(this.props.dispatch);
  }

  successCampaign = () => {
    this.closeCampaign();
    campaignActions.getAllCampaigns(this.props.dispatch);
  };

  render() {
    const {showAddCampaign} = this.state;
    const {campaign = [], clients, loading} = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const sampleReportId = query.get("sampleReportId");
    let samples = campaign;
    samples = samples.filter(sample => sample.client);
    samples = this.state.filterClientId === -1 ? samples : samples.filter(sample => sample.client.id === this.state.filterClientId);
    samples = Matches(samples, this.state.search, ["name"]);

    const copyCampaignLinkToClipboard = (token, event) => {
      event.stopPropagation();
      alertActions.notificationSuccess(this.props.dispatch, "Copied", "link copied to clipboard");
      navigator.clipboard.writeText("https://" + window.location.hostname + "/clientsample/" + token);
    }

    const handleVoteButtonClick = (e, sampleId) => {
      if (e) {
        e.stopPropagation();
      }
      fetch(`${BaseUrl}campaign/${sampleId}/votesReport`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': getBearer()
        })
      }).then(async (response) => {
        if (!response.ok) {
          alert("Failed to download");
          return;
        }
        const blob = await response.blob();
        var windowUrl = window.URL || window.webkitURL;
        var url = windowUrl.createObjectURL(blob);
        var anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = "sample_votes_" + sampleId + ".xls";
        anchor.click();
        anchor.remove();
        windowUrl.revokeObjectURL(url);
        if (!e) {
          window.location = "/samples";
        }
      }).catch(error => {
        console.log(error);
      });

    }
    if (sampleReportId) {
      handleVoteButtonClick(null, sampleReportId);
    }
    let imagesUrls = [...new Set(clients.flatMap(client => client.locations.map(location => {
      location["client"] = client;
      return location;
    })).filter(location => location.logoUrl).map(location => ({
          clientId: location.client.id,
          logoUrl: location.logoUrl
        }
    ))), ...clients.filter(client => client.logoUrl).map(client => ({
          clientId: client.id,
          logoUrl: client.logoUrl
        }
    ))];

    imagesUrls = [...imagesUrls, ...new Set(samples.filter(sample => sample.artworkUrl).map(
        sample => ({
              clientId: sample.client.id,
              logoUrl: sample.artworkUrl
            }
        )))];

    imagesUrls = imagesUrls.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.clientId === current.clientId && item.logoUrl === current.logoUrl)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return (
        <>
                {showAddCampaign && (
                    <CreateCampaign
                        onBack={this.closeCampaign}
                        onCreate={this.successCampaign}
                        campaign={this.state.campaign}
                        onArchive={this.closeCampaign}
                        imagesUrls={imagesUrls}
                    />
                )}
          <>
                    <SectionHeader>
                        <RowSpaced>
                            <SectionTitle>Samples</SectionTitle>
                            <PrimaryButton onClick={() => this.setState({showAddCampaign: true})}>+ Add
                                Sample</PrimaryButton>
                        </RowSpaced>

                        <FilterSection>
                            <SearchFilter onChange={this.handleSearch} value={this.state.search} />
                            <ClientDropdownFilter clientSelect={this.filterClient} />
                        </FilterSection>
                    </SectionHeader>

                    <SortTable
                        defaultSortField='name'
                        loading={loading}
                        rows={samples}
                        gutter={SectionGutter}
                        onRowClick={this.handleEdit}
                        columns={[
                          {percent: 1, field: 'name', title: 'name'},
                          {percent: 1, field: 'client.name', title: 'client'},
                          {percent: 1, field: 'description', title: 'description', isNumber: true},
                          {percent: 1, field: 'playlists.length', title: 'playlists', isNumber: true},
                          {
                            percent: 1,
                            field: 'dateCreated',
                            title: 'Created (Local)',
                            isNumber: true,
                            rowRenderer: row => (
                                <StandardDateMomentWithFormat
                                    format={"MM-DD-YYYY"}>{new Date(row.dateCreated)}</StandardDateMomentWithFormat>
                            )
                          },
                          {
                            percent: 1, field: 'token', title: 'Public link', isNumber: true, rowRenderer: row => (
                                <LinkButton style={{backgroundColor: '#E39F20'}}
                                    onClick={(e) => copyCampaignLinkToClipboard(row.token, e)}><FontAwesomeIcon
                                    icon={'copy'}></FontAwesomeIcon>&nbsp;Copy</LinkButton>
                            )
                          },
                          {
                            percent: 1, field: 'voteCount', title: 'VOTES', isNumber: true, rowRenderer: row => {
                              const voteCount = row.voteCount;
                              if (voteCount === 0) {
                                return <LinkButton status={"NO_VOTES"}>0 Votes</LinkButton>
                              } else {
                                if (row.finalVoteSubmited) {
                                  return <LinkButton status={"FEEDBACK_SUBMITED"}
                                      onClick={(e) => handleVoteButtonClick(e, row.id)}> {voteCount} votes</LinkButton>
                                } else {
                                  return <LinkButton status={"VOTES_PRESENT"}
                                      onClick={(e) => handleVoteButtonClick(e, row.id)}> {voteCount} votes</LinkButton>
                                }
                              }
                            }
                          },
                          {
                            percent: 1,
                            field: 'lastVoteActivity',
                            title: 'Activity',
                            isNumber: true,
                            rowRenderer: row => (
                                row.lastVoteActivity ?
                                    <StandardDateMomentWithFormat
                                        format={"MM-DD-YYYY"}>{new Date(row.lastVoteActivity)}</StandardDateMomentWithFormat> : <></>
                            )
                          },
                        ]}
                    />
                </>
            </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  sampleReportId: props.match.params.sampleReportId,
  alert: state.alert,
  loading: state.campaign.loading,
  campaign: state.campaign.campaign,
  clients: state.client.client
});

const connectedAllCampaigns = withRouter(connect(mapStateToProps)(AllCampaigns));
export { connectedAllCampaigns as AllCampaigns };

